import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Sidebar from "../components/sidebar"

const NewslettePage = ({ location }) => (
  <Layout>
    <SEO
      pageTitle="Voilà　利用規約"
      showSiteNameInTitle="true"
      pageDescription=""
      pagePath={location.pathname}
    />
    <div className="container flex-row">
      <div className="terms main">
        <h1>Voilà　利用規約</h1>
        <h2>1. 免責事項</h2>
        <p>
          当社は、本サービスの内容・正常動作性・機能性について、可能な限り保守する努力をいたします。ただし、これらの活動に対しては、明示または黙示を問わず、いかなる保証も行いません。また、本サービスの内容および機能の編集・管理・削除等に関する行為に対する義務、およびこれに必要な技術的手段を利用者に提供する義務は、これを負わないものとします。
          </p>
        <p>
          ユーザーは、以下の事項を了承した上で、本サービスを利用するものとします。
          </p>
        <ul className="ul-w_80">
          <li>本サービスの変更・中断・遅延・停止・終了あるいは不具合が発生する可能性があること</li>
          <li>本サービスの利用中、第三者または他のユーザーとのトラブルが生じた場合は、ユーザーの責任において対応すること</li>
          <li>本サービスを通じて第三者から取得した情報等、もしくは第三者との取引等により生じたトラブルに対しては、ユーザーの責任において対応すること</li>
          <li>上記各項目について、当社がその責を免れること</li>
        </ul>
        <p>
          当社は、本サービスの提供に関わる技術障害、サイト保守、天災地変、紛争、疫病の流行およびその他の不可抗力による事由、あるいは当社が必要と考える事由に基づき、本サービスの内容を変更、あるいは本サービスの提供を中断できるものとします。
          </p>
        <h2>2. 禁止事項</h2>
        <p>
          ユーザーは、本サービスに関して、以下の行為を行わないものとします。
          </p>
        <ul className="ul-w_80">
          <li>本サービスのコンテンツ等（外部コンテンツを含む。以下同様とします）を第三者へ提供・再配信する行為</li>
          <li>本サービスのコンテンツ等の複製（閲覧時、一時的に発生する電子的蓄積は除く）、編集、加工、翻訳、翻案、出版、転載、頒布、放送、口述、展示、販売、公衆送信および改変など、当社および第三者の著作権を侵害する行為やそれを助長する行為、あるいは当該行為をしたコンテンツ等を第三者へ提供・再配信する行為やそれを助長する行為</li>
          <li>本サービスのコンテンツ等を、不正・営利を目的として利用する行為、あるいはその準備を目的とした行為</li>
          <li>本ザービスに対し、データマイニング、ロボット等によるデータ収集・抽出ツールを使用する行為</li>
          <li>法令もしくは公序良俗に違反する、あるいはその恐れのある行為</li>
          <li>その他、当社が不適切と判断する行為</li>
        </ul>
        <h2>3. 著作権</h2>
        <p>
          本サービスのコンテンツ等に関する著作権は、Doitsu News Digest GmbHが保有します。 本サービスが提供するすべてのコンテンツ等（記事、画像、動画、データ、ノウハウ、商標）は、ドイツ著作権法およびEU著作権法の下に保護されます。ユーザーは、著作権法の制限を超えない場合のみにおいて、本サービスのコンテンツ等を使用することができます。
          </p>
        <p>
          ユーザーは、本サービスのコンテンツ等を商用目的で使用することを希望する場合、メールにて事前の申請を行い、許諾を得るものとします。
          </p>
        <h2>5. 規則違反</h2>
        <p>
          当社は、ユーザーが本規約等に違反した場合、当該ユーザーによる本サービスの利用を停止および禁止することができるものとします。また、当該ユーザーによる違反行為のために当社が損害を被った場合、当社は、当該ユーザーに対し、当該違反行為によって当社が被った損害額を請求することができるものとします。
          </p>
        <h2>6. サイト内のリンク等</h2>
        <p>
          本サービスは、外部Webサイト等へのリンクをしている場合があります。また、第三者が本サービス上において、外部Webサイト等へのリンクを提供している場合があります。当該外部サイト等については、各運営主体が管理し、当社は管理の権利および義務を有しません。当社は、ユーザーに対し、外部サイト等における情報、広告、商品または役務等に関する保証を行いません。
          </p>
        <h2>7. Voilàへのリンク</h2>
        <p>
          ユーザーは、下記のいずれにも該当しない場合において、本サービスへのリンクを行うことができます。
          </p>
        <ul className="ul-w_80">
          <li>営利目的である場合</li>
          <li>Voilàの設立目的、企業理念またはイメージ等に合わない場合</li>
          <li>当社に経済的損失が生じると考えられる場合</li>
          <li>Voilàへのリンクである旨を明記しない場合</li>
          <li>独自フレームの中に、Voilàサイトを取り込む形でリンクする場合</li>
          <li>第三者が著作権を保有し、リンクを禁止している場合</li>
          <li>その他、Voilàの運営に支障をきたす可能性があると当社が判断した場合</li>
        </ul>
        <h2>8. 引用</h2>
        <p>
          ユーザーは、ドイツ著作権法に基づき、正当と認められる範囲において、本サービスのコンテンツを引用することができます。
          </p>
        <h2>9. 転載</h2>
        <p>
          ユーザーは、本サービスが提供する記事・写真・図表等の転載を希望する場合、転載する内容と利用方法をメールにて申請し、当社の許可を得る必要があります。また、本サービスにより提供される記事・写真・図表等の無断転載は、これを禁止します。
          </p>
        <h2>10. 規約の変更</h2>
        <p>
          当社は、必要と判断した場合、ユーザーによる事前承諾を得ることなく、本規約等を変更できるものとします。この場合、当社は、合理的な事前告知期間を設けるものとします。当社は、本規約等変更後に、ユーザーが本サービスを利用したことをもって、ユーザーが変更後の本規約等の内容を承諾したものとみなします。ユーザーは、変更後の本規約の適用について異議がある場合は、本サービスを利用しないこととします。
          </p>
        <h2>11. プライバシーの考え方</h2>
        <p>
          当社が本サービスを通じて取得した個人情報、およびサービスの利用状況に関する情報は、当社が規定するプライバシーポリシー、およびEU一般データ保護規則（GDPR）、連邦データ保護法（BDSG）、場合によっては特別法による規定に準じて取り扱うものとします。
          </p>
        <h2>12. 広告</h2>
        <p>
          Voilàサイトは、広告収入を目的としたバナー等の広告枠を設けています。ユーザーがVoilàを訪れ、広告をクリックしたことによって遷移した先でのプライバシーに関しましては、そのサイト独自のプライバシーポリシーをご確認ください。なお、遷移先のサイトにて発生した事象に関しましては、当社では責任を負いかねます。
          </p>
        <h2>13. その他</h2>
        <p>
          本規約等はドイツ国内法およびEU法に準拠し、解釈されます。
          </p>



      </div>
      <Sidebar />
    </div>
  </Layout>
)

export default NewslettePage
